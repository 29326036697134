<template>
  <div>
    <nav class="navbar top" role="navigation" aria-label="main navigation">
      <div class="navbar-brand is-flex-grow-1	">
        <div class="navbar-item is-justify-content-space-between is-flex-grow-1">
          <h1 class="title mb-0">
            Asset Manager
          </h1>

          <button class="button is-black" @click="showUploadModal = true">
            <Icon icon="plus" class="is-hidden-mobile" />
            <span>Upload file</span>
          </button>
        </div>
      </div>
    </nav>

    <section v-if="user && logos" class="section">
      <template v-if="!logos.length">
        <h3 class="subtitle mt-6 mb-3">
          No files uploaded. Click the button to upload files now.
        </h3>
        <p class="has-text-grey">
          Images should be transparent and follow sizing guidelines for best results.
        </p>
      </template>

      <div class="columns is-multiline is-mobile">
        <div v-for="logo in logos" :key="logo.id" class="column is-6-mobile is-4-tablet is-2-desktop is-relative">
          <button
            class="button is-small is-rounded is-black"
            style="position: absolute; top: .25rem; right: .25rem; z-index: 5;"
            @click="assetToDelete = logo"
          >
            <Icon icon="fa-solid fa-trash" />
          </button>
          <figure class="image is-128x128 mx-auto is-flex is-align-items-center">
            <img :src="logo.downloadUrl">
          </figure>
          <p class="heading has-text-centered">
            {{ logo.name }}
          </p>
        </div>
      </div>
    </section>

    <Modal
      v-if="showUploadModal"
      title="Upload Asset"
      :isSmall="true"
      @close="showUploadModal = false"
    >
      <UploadFile
        :isBoxed="true"
        @fileSelected="uploadAsset"
        @uncropped="$emit('uncropped', $event)"
        class="mb-6"
      />
    </Modal>

    <Modal
      v-if="assetToDelete"
      title="Delete Asset"
      :isSmall="true"
      @close="assetToDelete = null"
    >
      Are you sure you want to delete this asset?

      <figure class="image is-128x128 mx-auto is-flex is-align-items-center">
        <img :src="assetToDelete.downloadUrl">
      </figure>
      <template v-slot:footer>
        <button class="button is-danger" @click="doDeleteAsset(assetToDelete)">
          Delete
        </button>
        <button class="button" @click="assetToDelete = null">
          Cancel
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { ref, inject, watch } from 'vue'
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import useDocument from '@/composables/useDocument'
import useCollection from '@/composables/useCollection'
import useStorage from '@/composables/useStorage'
import Icon from '@/components/Icon'
import Modal from '@/components/Modals/Modal'
import UploadFile from '@/components/UploadFile_OLD'
import { timestamp } from '@/firebase/config'
import filters from '@/utils/filters'

export default {
  components: { Icon, Modal, UploadFile },
  setup() {
    const { user } = getUser()
    const { documents: logos } = getCollection('accounts', null, user.value.uid, 'logos')
    const { uploadData, progress, error, resultData, deleteData } = useStorage('assets')

    console.log('logos', logos)

    const showUploadModal = ref(false)
    const assetToDelete = ref(null)
    const isPending = inject('isPending')

    const uploadAsset = async (file) => {
      console.log('uploadAsset', file)
      await uploadData(file)
    }

    const doAddLogo = async (fileInfo) => {
      const { addDoc: addLogo } = useCollection('accounts', user.value.uid, 'logos')
      await addLogo({
        ...fileInfo,
        tags: [],
        created: timestamp()
      })
      showUploadModal.value = false
    }

    watch(() => resultData.value, (resultData) => {
      console.log('resultData', resultData)
      doAddLogo(resultData)
    })

    const doDeleteAsset = async (asset) => {
      console.log('asset', asset)
      isPending.value = true
      await deleteData(asset.image.ref)
      const { deleteDoc: deleteAsset } = useDocument('accounts', user.value.uid, 'logos', asset.id)
      await deleteAsset()
      isPending.value = false
      assetToDelete.value = null
    }
 
    return {
      user,
      logos,
      isPending,

      showUploadModal,
      uploadAsset,
      error,

      assetToDelete,
      doDeleteAsset,

      ...filters
    }
  }
}
</script>